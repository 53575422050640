import React from "react"
import styled from "styled-components"

import Layout, {SectionTitle} from "../components/layout"
import SEO from "../components/seo"

import Container from "../components/Container";
import { RalewayBold } from "../components/Text";
import { Colors } from "../constants/Colors";
import { mediaHelper } from "../utils/media-helper";

import WefitLogoDark from "../assets/img/wefit-logo-dark.png";
import WefitLogoDark2x from "../assets/img/wefit-logo-dark@2x.png";
import WefitLogoDark3x from "../assets/img/wefit-logo-dark@3x.png";
import WefitLogoDark4x from "../assets/img/wefit-logo-dark@4x.png";

const StyledContainer = styled(Container)`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mediaHelper.md`    
    padding-top: 100px;    
  `};
`;

const StyledTitle = styled.h1`
  ${RalewayBold}
  letter-spacing: 0.03em;
  color: ${Colors.YELLOW};
  text-transform: initial;
  opacity: 1;
  font-size: 40px;
  line-height: 42px;

  ${mediaHelper.md`    
    font-size: 70px;
    line-height: 64px;
  `};
`;

const NotFoundPage = () => (
  <StyledContainer>
    <img
      alt="wefit-logo"
      srcSet={`
        ${WefitLogoDark4x} 4x,
        ${WefitLogoDark3x} 3x,
        ${WefitLogoDark2x} 2x,
        ${WefitLogoDark} 1x`}
      src={WefitLogoDark}  
    />
    <SEO title="404: Not found" />
    <StyledTitle>404</StyledTitle>
    <SectionTitle>Página não encontrada.</SectionTitle>
  </StyledContainer>
)

export default NotFoundPage
